<template>
  <div>
    <v-card
      v-if="order"
      outlined
      class="mb-3"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title> Datos de envío </v-card-title>
      <v-card-text class="px-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            outlined
            label="Email"
            v-model="order.email"
            class="px-4 rounded-lg"
            :rules="rules"
            hide-details="auto"
          >
          </v-text-field>

          <v-divider class="my-3"></v-divider>

          <template v-for="(attribute, i) in address">
            <v-text-field
              outlined
              class="px-4 rounded-lg"
              :label="
                $t('address.' + attribute) +
                (['unit'].includes(attribute) ? ' (opcional)' : '')
              "
              :disabled="attribute == 'country'"
              :rules="['unit'].includes(attribute) ? [] : rules"
              :type="
                ['unit', 'idNumber', 'phone', 'number'].includes(attribute)
                  ? 'number'
                  : 'text'
              "
              v-model="order.addressAttributes[attribute]"
            >
            </v-text-field>
          </template>

          <div class="px-4 mb-6">
            <vue-google-autocomplete
              id="map"
              classname="form-control"
              placeholder="
              Dirección
              "
              :value="order.addressAttributes.fullAddress"
              v-on:placechanged="setPlace"
              class="rounded-lg px-3 fill-width v-input theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"
              style="height: 56px; border: 1px solid darkgrey"
            >
            </vue-google-autocomplete>
          </div>

          <v-text-field
            outlined
            class="px-4 rounded-lg"
            label="Piso / departamento (opcional)"
            placeholder="Piso 4 departamento C"
            v-model="order.addressAttributes.unit"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="alert.active"
      :color="alert.type"
      :dismissible="alert.type == 'error'"
      class="rounded-b-0 mb-0"
    >
      {{ alert.message }}
    </v-snackbar>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

import { mapGetters } from "vuex";
import { EditOrder } from "@/graphql/mutations/order";

export default {
  data() {
    return {
      valid: true,
      alert: {
        message: null,
        active: false,
        type: null,
      },
      order: {
        email: null,
        state: "payment",
        addressAttributes: {
          id: null,
          fullAddress: null,
          lat: null,
          lng: null,
          fullname: null,
          idNumber: null,
          phone: null,
          street: null,
          number: null,
          unit: null,
          zipcode: null,
          city: null,
          state: null,
          country: "Chile",
        },
      },
      rules: [(v) => !!v || "Campo obligatorio."],
    };
  },

  props: {
    currentOrder: {
      type: Object,
      required: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    address() {
      return ["fullname", "idNumber", "phone"];
    },

    ...mapGetters(["currentUser"]),
  },

  watch: {
    currentOrder(val) {
      this.order.email = val.email || (this.currentUser || {}).email;

      if (val.address && val.address.__typename) {
        delete val.address.__typename;
      }
      this.order.addressAttributes = {
        ...this.order.addressAttributes,
        ...val.address,
      };
    },
  },

  mounted() {
    if (process.env.VUE_APP_LOCATION == "ar") {
      this.order.addressAttributes.country = "Argentina";
    }

    if (this.currentOrder) {
      this.order.email =
        this.currentOrder.email || (this.currentUser || {}).email;
      if (this.currentOrder.address && this.currentOrder.address.__typename) {
        delete this.currentOrder.address.__typename;
      }
      this.order.addressAttributes = {
        ...this.order.addressAttributes,
        ...this.currentOrder.address,
      };
    }
  },

  methods: {
    next() {
      if (!this.order.addressAttributes.number) {
        this.alert.message = "Altura de calle obligatoria";
        this.alert.type = "error";
        this.alert.active = true;
      } else {
        this.valid = this.$refs.form.validate();

        if (this.valid) {
          this.$apollo
            .mutate({
              mutation: EditOrder,
              variables: {
                input: {
                  orderToken: this.currentOrder.orderToken,
                  attributes: {
                    ...this.order,
                  },
                },
              },
            })
            .then((res) => {
              this.$emit("next");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    setPlace(place) {
      if (!place.street_number) {
        this.alert.message = "Altura de calle obligatoria";
        this.alert.type = "error";
        this.alert.active = true;
        this.order.addressAttributes.number = null;
        setTimeout(() => {
          this.order.addressAttributes.fullAddress = null;
        }, 100);
      } else {
        this.alert.active = false;
        this.order.addressAttributes = {
          ...this.order.addressAttributes,
          lat: place.latitude,
          lng: place.longitude,
          street: place.route,
          number: place.street_number,
          neighbourhood: place.locality,
          city: place.administrative_area_level_2,
          state: place.administrative_area_level_1,
          country: place.country,
        };
      }
    },
  },

  components: { VueGoogleAutocomplete },
};
</script>
